import ElementUI from 'element-ui'
// 成功弹窗
const prompt = function (type, cent, callback) {
    ElementUI.Message.closeAll()
    ElementUI.Message({
        message: cent,
        type: type,
        center: true,
        duration: 1500,
        onClose: function () {
            if (callback != undefined && typeof callback === "function") {
                callback();
            }
        }
    });
};
// 成功弹窗
const prompt2 = function (type, cent, callback) {
    ElementUI.Message.closeAll()
    ElementUI.Message({
        message: cent,
        type: type,
        center: true,
        duration: 1500,
        dangerouslyUseHTMLString: true,
        onClose: function () {
            if (callback != undefined && typeof callback === "function") {
                callback();
            }
        }
    });
};
// 文本提示消息
const template = function (type, title, cent, callback) {
    ElementUI.Notification.closeAll()
    ElementUI.Notification({
        type: type,
        title: title,
        message: cent,
        duration: 4500,
        onClose: function () {
            if (callback != undefined && typeof callback === "function") {
                callback();
            }
        }
    });
}
// 删除弹窗提醒
const confirm = function (cent, suCallback) {
    ElementUI.MessageBox.confirm(cent, {
        title: '提示',
        message: cent,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        if (suCallback != undefined && typeof suCallback === "function") {
            suCallback();
        }
    }).catch(() => {
        prompt('infor', '已取消了操作');
    })
}
// 加载弹窗提醒
const loading = function (cent) {
    return ElementUI.Loading.service({
        lock: true,
        text: cent,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}
// 时间格式转换
const dateTime = function (type) {
    let date = new Date();
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s;
    if (type == 'yyyy-MM-dd') {
        return y + '-' + MM + '-' + d;
    } else if (type == 'yyyy-MM-dd HH:mm:ss') {
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    } else if (type == 'yyyy年MM月dd日') {
        return y + '年' + MM + '月' + d + '日';
    } else if (type == 'yyyy年MM月dd日 HH时mm分ss秒') {
        return y + '年' + MM + '月' + d + '日 ' + h + '时' + m + '分' + s + '秒';
    }
}

const listData = function (waterfallData) {
    let [...newWaterfallData] = [[], [], [], []]
    let arr1 = [] // 第一列的值
    let arr2 = [] // 第二列的值
    let arr3 = [] // 第二列的值
    let arr4 = [] // 第二列的值
    let heightArry_1 = [] // 第一列的卡片高度
    let heightArry_2 = [] // 第二列的卡片高度
    let heightArry_3 = [] // 第二列的卡片高度
    let heightArry_4 = [] // 第二列的卡片高度
    waterfallData.forEach((item, index) => {
        console.log(item.high)
        if (index === 0) { // 第一行中的元素无需判断，直接加到新的数组中
            heightArry_1.push(item.high)
            arr1.push(waterfallData[index])
            return
        }
        if (index === 1) {
            heightArry_2.push(item.high)
            arr2.push(waterfallData[index])
            return
        }
        if (index === 2) {
            heightArry_3.push(item.high)
            arr3.push(waterfallData[index])
            return
        }
        if (index === 3) {
            heightArry_4.push(item.high)
            arr4.push(waterfallData[index])
            return
        }
        // console.log(heightArry_1.length, heightArry_2.length, heightArry_3.length, heightArry_4.length)
        const heightTotal_1 = heightArry_1.length ? Array.from(heightArry_1).reduce((acc, cur) => acc + cur) : 0 // 第一列的总高度
        const heightTotal_2 = heightArry_2.length ? Array.from(heightArry_2).reduce((acc, cur) => acc + cur) : 0 // 第二列的总高
        const heightTotal_3 = heightArry_3.length ? Array.from(heightArry_3).reduce((acc, cur) => acc + cur) : 0 // 第三列的总高度
        const heightTotal_4 = heightArry_4.length ? Array.from(heightArry_4).reduce((acc, cur) => acc + cur) : 0 // 第三列的总高度

        // 找到最小值
        let minNumber = Math.min(heightTotal_1, heightTotal_2, heightTotal_3, heightTotal_4)
        //console.log(minNumber)
        switch (minNumber) {
            case heightTotal_1:
                heightArry_1.push(item.high)
                arr1.push(waterfallData[index])
                break
            case heightTotal_2:
                heightArry_2.push(item.high)
                arr2.push(waterfallData[index])
                break
            case heightTotal_3:
                heightArry_3.push(item.high)
                arr3.push(waterfallData[index])
                break
            case heightTotal_4:
                heightArry_4.push(item.high)
                arr4.push(waterfallData[index])
                break
        }
    })
    // 重新将数据赋值给各列数组
    newWaterfallData[0] = arr1
    newWaterfallData[1] = arr2
    newWaterfallData[2] = arr3
    newWaterfallData[3] = arr4
    return newWaterfallData
}
const listDataNo = function (waterfallData) {
    let [...newWaterfallData] = [[], [], [], []]
    waterfallData.forEach((el, i) => {
        switch (i % 4) {
            case 0: newWaterfallData[0].push(el)
                break
            case 1: newWaterfallData[1].push(el)
                break
            case 2: newWaterfallData[2].push(el)
                break
            case 3: newWaterfallData[3].push(el)
                break
        }
    });
    return newWaterfallData;
}
export default {
    prompt,
    prompt2,
    template,
    confirm,
    loading,
    dateTime,
    listData,
    listDataNo
}