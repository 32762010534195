import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			name: '/',
			component: () => import('../components/public.vue'),
			redirect: '/index',
			children: [{
					path: '/index',
					name: 'index',
					meta: {
						title: '首页',
					},
					component: () => import('../views/index.vue')
				},
				{
					path: '/material',
					name: 'material',
					meta: {
						title: '字体素材'
					},
					component: () => import('../views/material.vue')
				},
				{
					path: '/materialDetail',
					name: 'materialDetail',
					meta: {
						title: '字体素材详情'
					},
					component: () => import('../views/materialDetail.vue')
				},
				{
					path: '/video',
					name: 'video',
					meta: {
						title: '视频教程'
					},
					component: () => import('../views/video.vue')
				},
				{
					path: '/videoDetail',
					name: 'videoDetail',
					meta: {
						title: '视频详情'
					},
					component: () => import('../views/videoDetail.vue')
				},
				{
					path: '/ranking',
					name: 'ranking',
					meta: {
						title: '每周上榜'
					},
					component: () => import('../views/ranking.vue')
				},
				{
					path: '/rankingDetail',
					name: 'rankingDetail',
					meta: {
						title: '每周上榜详情'
					},
					component: () => import('../views/rankingDetail.vue')
				},
				{
					path: '/dailyWord',
					name: 'dailyWord',
					meta: {
						title: '每周一字'
					},
					component: () => import('../views/dailyWord.vue')
				},
				{
					path: '/dailyWordDetail',
					name: 'dailyWordDetail',
					meta: {
						title: '每周一字详情'
					},
					component: () => import('../views/dailyWordDetail.vue')
				},
				{
					path: '/dailyWordUpload',
					name: 'dailyWordUpload',
					meta: {
						title: '每周一字上传'
					},
					component: () => import('../views/dailyWordUpload.vue')
				},
				{
					path: '/fontSearch',
					name: 'fontSearch',
					meta: {
						title: '字体搜索'
					},
					component: () => import('../views/fontSearch.vue')
				},
				{
					path: '/fontSearchDetail',
					name: 'fontSearchDetail',
					meta: {
						title: '字体搜索详情'
					},
					component: () => import('../views/fontSearchDetail.vue')
				},
				{
					path: '/deformation',
					name: 'deformation',
					meta: {
						title: '变形宝典'
					},
					component: () => import('../views/deformation.vue')
				},
				{
					path: '/deformationBind',
					name: 'deformationBind',
					meta: {
						title: '变形宝典绑定'
					},
					component: () => import('../views/deformationBind.vue')
				},
				{
					path: '/appDownload',
					name: 'appDownload',
					meta: {
						title: 'APP下载'
					},
					component: () => import('../views/appDownload.vue')
				},
				{
					path: '/project',
					name: 'project',
					meta: {
						title: '专题列表'
					},
					component: () => import('../views/project.vue')
				},
				{
					path: '/projectDetail',
					name: 'projectDetail',
					meta: {
						title: '专题详情'
					},
					component: () => import('../views/projectDetail.vue')
				},
				{
					path: '/downLoad',
					name: 'downLoad',
					meta: {
						title: '下载清单'
					},
					component: () => import('../views/downLoad.vue')
				},
				{
					path: '/auditionlist',
					name: 'auditionlist',
					meta: {
						title: '试听专区'
					},
					component: () => import('../views/auditionlist.vue')
				},
				{
					path: '/matchList',
					name: 'matchList',
					meta: {
						title: '下载清单'
					},
					component: () => import('../views/match/list.vue')
				},
				{
					path: '/match',
					name: 'match',
					meta: {
						title: '设计大赛'
					},
					component: () => import('../views/match/match.vue'),
					redirect: '/matchIndex',
					children: [{
						path: '/matchIndex',
						name: 'matchIndex',
						meta: {
							title: '设计大赛'
						},
						component: () => import('../views/match/index.vue')
					},
					{
						path: '/matchForm',
						name: 'matchForm',
						meta: {
							title: '提交作品'
						},
						component: () => import('../views/match/form.vue')
					},
					{
						path: '/matchWorks',
						name: 'matchWorks',
						meta: {
							title: '我的作品'
						},
						component: () => import('../views/match/myWorks.vue')
					},
					{
						path: '/matchRanking',
						name: 'matchRanking',
						meta: {
							title: '获奖公示'
						},
						component: () => import('../views/match/ranking.vue')
					},
					{
						path: '/matchDetail',
						name: 'matchDetail',
						meta: {
							title: '作品详情'
						},
						component: () => import('../views/match/detail.vue')
					}
					]
				},
				
				{
					path: '/user',
					name: 'user',
					meta: {
						title: '个人中心'
					},
					component: () => import('../views/user/user.vue'),
					redirect: '/userDaily',
					children: [{
							path: '/userDaily',
							name: 'userDaily',
							meta: {
								title: '个人中心-每周一字'
							},
							component: () => import('../views/user/userDaily.vue')
						},
						{
							path: '/userCollect',
							name: 'userCollect',
							meta: {
								title: '个人中心-我的收藏'
							},
							component: () => import('../views/user/userCollect.vue')
						},
						{
							path: '/userFavorite',
							name: 'userFavorite',
							meta: {
								title: '个人中心-收藏夹列表'
							},
							component: () => import('../views/user/userFavorite.vue')
						},
						{
							path: '/userDownload',
							name: 'userDownload',
							meta: {
								title: '个人中心-我的下载'
							},
							component: () => import('../views/user/userDownload.vue')
						},
						{
							path: '/userLearn',
							name: 'userLearn',
							meta: {
								title: '个人中心-学习记录'
							},
							component: () => import('../views/user/userLearn.vue')
						},
						{
							path: '/userInfo',
							name: 'userInfo',
							meta: {
								title: '个人中心-账号信息'
							},
							component: () => import('../views/user/userInfo.vue')
						},
						{
							path: '/userFans',
							name: 'userFans',
							meta: {
								title: '个人中心-我的粉丝'
							},
							component: () => import('../views/user/userFans.vue')
						},
						{
							path: '/userFocus',
							name: 'userFocus',
							meta: {
								title: '个人中心-我的关注'
							},
							component: () => import('../views/user/userFocus.vue')
						},
						{
							path: '/userOrder',
							name: 'userOrder',
							meta: {
								title: '个人中心-我的订单'
							},
							component: () => import('../views/user/userOrder.vue')
						},
						{
							path: '/userWork',
							name: 'userWork',
							meta: {
								title: '个人中心-我的作业'
							},
							component: () => import('../views/user/userWork.vue')
						},
						{
							path: '/userCoupon',
							name: 'userCoupon',
							meta: {
								title: '个人中心-优惠券/码'
							},
							component: () => import('../views/user/userCoupon.vue')
						},
						{
							path: '/userInvite',
							name: 'userInvite',
							meta: {
								title: '个人中心-邀请页面'
							},
							component: () => import('../views/user/userInvite.vue')
						},
						{
							path: '/userWithdraw',
							name: 'userWithdraw',
							meta: {
								title: '个人中心-提现记录'
							},
							component: () => import('../views/user/userWithdraw.vue')
						}
					]
				},
				{
					path: '/userNews',
					name: 'userNews',
					meta: {
						title: '个人中心-消息中心'
					},
					component: () => import('../views/user/userNewsList.vue')
				},
				{
					path: '/vip',
					name: 'vip',
					meta: {
						title: '会员'
					},
					component: () => import('../views/vip.vue')
				},
				{
					path: '/faq',
					name: 'faq',
					meta: {
						title: '常见问题'
					},
					component: () => import('../views/faq.vue')
				},
				{
					path: '/refresh',
					name: 'refresh',
					meta: {
						title: ''
					},
					component: () => import('../views/refresh/refresh.vue')
				},
				{
					path: '/userAgreement',
					name: 'userAgreement',
					meta: {
						title: '协议中心'
					},
					component: () => import('../components/userAgreement.vue')
				},
				{
					path: '/arrondi',
					name: 'arrondi',
					meta: {
						title: '会员专区'
					},
					component: () => import('../views/arrondi.vue')
				},
				{
					path: '/arrondiDetail',
					name: 'arrondiDetail',
					meta: {
						title: '会员专区'
					},
					component: () => import('../views/arrondiDetail.vue')
				},
				{
					path: '/integral',
					name: 'integral',
					meta: {
						title: '积分兑换'
					},
					component: () => import('../views/integral/integral.vue'),
					redirect: '/integralShop',
					children: [
						{
							path: '/integralShop',
							name: 'integralShop',
							meta: {
								title: '积分兑换'
							},
							component: () => import('../views/integral/shop.vue')
						},
						{
							path: '/integralRecord',
							name: 'integralRecord',
							meta: {
								title: '积分记录'
							},
							component: () => import('../views/integral/record.vue')
						},
						{
							path: '/integralIntro',
							name: 'integralIntro',
							meta: {
								title: '积分介绍'
							},
							component: () => import('../views/integral/intro.vue')
						}
					]
				}
			]
		},
		{
			path: '/agreement',
			name: 'agreement',
			meta: {
				title: ''
			},
			component: () => import('../components/agreement.vue')
		}
		
	]
})


export default router
