import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import { store } from '../../store/store'
import utile from '../public/utile.js';

const url = 'https://backend.zitisheji.com';

axios.defaults.baseURL = 'https://backend.zitisheji.com/api/';
axios.defaults.dataType = 'json'
// localStorage.setItem('access_token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDkzODg1NTcsImV4cCI6MTczNTc4ODU1NywianRpIjoiZmUxNDNlMmEyNmViNGNiMzg4OTU5YTg4YTllNTcyMDk1MWIxMWIxNSIsInVpZCI6ODcxM30.5JUnsVFqBwbhyNdOM83kRwugHAX_zqv58oXv2nkNkow')

axios.interceptors.request.use(config => {
    config.headers['token'] = localStorage.getItem('access_token')||'';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    // config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
},response => {

    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据

    // 否则的话抛出错误

    if (response.status === 200) {

        return Promise.resolve(response);

    } else {

        return Promise.reject(response);

    }

},)

const POST = function (url, parametero, parametert) {
    var data = typeof parametero==='function'?'':parametero;
    var callback = typeof parametero==='function'?parametero:parametert;

    axios.post(url, qs.stringify(data)).then(function (res) {
        if(res.data.code == 406){
            localStorage.removeItem('access_token')
            localStorage.removeItem('userInfo');
            console.log('```',store)
            store.commit("userInfo", '');
            utile.prompt('error', '账号已在其他设备登录，请重新登录');
            //loginShow();
            exitfun(res.data);
        } 
        if (res.data.code == 200) {
            callback(res.data);
        }
    }).catch(function (res) {
        if (res.response) {
            exitfun(res.response.data);
        }
    })
}

const POST500 = function (url, parametero, parametert) {
    var data = typeof parametero==='function'?'':parametero;
    var callback = typeof parametero==='function'?parametero:parametert;

    axios.post(url, qs.stringify(data)).then(function (res) {
        if(res.data.code == 406){
            localStorage.removeItem('access_token')
            localStorage.removeItem('userInfo');
            console.log('```',store)
            store.commit("userInfo", '');
            utile.prompt('error', '账号已在其他设备登录，请重新登录'); 
            console.log(store.state.userInfo);
            // eslint-disable-next-line no-undef
            loginShow();

        }
        if (res.data.code != 200 && res.data.code != 500) {
            exitfun(res.data);
        } else {
            callback(res.data);
        }
    }).catch(function (res) {
        if (res.response) {
            if(res.response.data.code == 500){
                callback(res.response.data);
            }else{
                exitfun(res.response.data);
            }
            
        }
    })
}

const download = function (url, parametero, parametert) {
    var data = typeof parametero==='function'?'':parametero;
    var callback = typeof parametero==='function'?parametero:parametert;

    axios.post(url, qs.stringify(data),{'responseType':'arraybuffer'}).then(function (res) {
        if(res.data.code == 406){
            localStorage.removeItem('access_token')
            localStorage.removeItem('userInfo');
            console.log('```',store)
            store.commit("userInfo", '');
            utile.prompt('error', '账号已在其他设备登录，请重新登录'); 
            console.log(store.state.userInfo);
            // eslint-disable-next-line no-undef
            loginShow();
            return ;
        }
        callback(res.data);
        
    }).catch(function (res) {
        if (res.response) {
            if(res.response.data.code == 500){
                callback(res.response.data);
            }else{
                exitfun(res.response.data);
            }
            
        }
    })
}

 

const GET = function(url, data, callback) {
    axios.post(url, {
        params: data
    }).then(function(res) {
        if (res.data.code != 1) {
            exitfun(res.data);
        } else {
            callback(res.data);
        }
    }).catch(function(res) {
        if (res.response) {
            exitfun(res.response.data);
        }
    })
}

//文件流
const FILE = function(url, data, callback) {
    axios.post(url, data,{
        responseType: 'blob',
    }).then(function(res) {
        callback(res.data);
    })
}

// 退出方法
const exitfun = function (res) {
    if (res.code == 500) {
        utile.prompt('error',res.msg);
    } else if (res.code == 406) { 
        if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('userInfo');
            // console.log('```',store)
            store.commit("userInfo", '')
            utile.prompt('error', '登录过期，请重新登录');
            setTimeout(() => {
                window.location.href="https://www.zitisheji.com/"
            }, 1500);
        } else {
            utile.prompt('error', '未登录，请先登录');
            // eslint-disable-next-line no-undef
            loginShow();
        }
        
    }
    
}

export default {
    POST,
    POST500,
    download,
    GET,
    FILE,
    url
}