<template>
  <div id="app">
    <router-view></router-view>
        
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    if(window.location.host == 'zitisheji.com'){
      window.location.href='www.zitisheji.com';
    }else{
      this.sizeFun();
    }
    var browerWidth = window.innerWidth; //浏览器可视宽度
    if (1180 <browerWidth < 1565) {
      var baseWidth = 1565; //设计稿宽度
      var zoomValue = browerWidth / baseWidth; //缩放比例计算
      console.log(browerWidth);
      //document.getElementById("test").style.transform ="scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
      if(zoomValue<1){
        document.getElementById("app").style.overflowX = 'hidden';
      }
      
      // document.getElementById("test").style.transform ="scale(" + zoomValue + "," + zoomValue + ")";
      // document.getElementById("test").style['-moz-transform-origin'] = 'left top';
    }
    if (window.location.href.indexOf('chinaz.com')!=-1) {
      localStorage.setItem('source', 1)
    }else if (window.location.href.indexOf('uiiiuiii.com')!=-1) {
      localStorage.setItem('source', 2)
    }
  },
  methods: {
    sizeFun() {
      // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (/MicroMessenger|Mobi|Android|iPhone/i.test(navigator.userAgent) || navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1) {
      
      //if (window.innerWidth < 760 && this.$route.name!='index') {

          let jumpUrl = "http://m.zitisheji.com/#/"
      //当前设备是移动设备
      // 视频

      if(window.location.href.indexOf('videoDetail') != -1){
        let as = window.location.href.split('?')[1]; //获取?之后的参数字符串
        jumpUrl = 'http://m.zitisheji.com/#/pages/video/uni_videodetail?'+as
      }
      //每周一字
      if(window.location.href.indexOf('rankingDetail') != -1){
        let bs = window.location.href.split('?')[1]; //获取?之后的参数字符串
        jumpUrl = 'http://m.zitisheji.com/#/pages/mycenter/uni_dailydetail?'+bs
  }
      //会员
      if(window.location.href.indexOf('vip') != -1){
        let vip = window.location.href.split('?')[1]; //获取?之后的参数字符串
        jumpUrl = 'http://m.zitisheji.com/#/pages/Tabblock/uni_vip?'+vip
      }
      
      window.location.href=jumpUrl;
        // var elink = document.createElement("a"); 
        // elink.style.display = "none";
        // elink.href = jumpUrl;
        // elink.click();
        
      
      }
    },
  },
  created(){
	
  },
  watch: {},
};
</script>

<style>
@font-face {
  font-family: 'sourcehansanscn';
  src: url('https://oss.zitisheji.com/SOURCEHANSANSCN-REGULAR.OTF') format('truetype');
}
#app {
  font-family: -apple-system,"MicrosoftYaHei,sourcehansanscn";
  /* text-align: center; */
  color: #2c3e50;
  background-color: #f6f6f6;
}
</style>
