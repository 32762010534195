import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        navList: [], //导航数据
        userInfo: {},//用户信息
        phone: /^1(3|4|5|6|7|8|9)\d{9}$/, // 验证手机号
        email: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, // 验证邮箱
    },
    mutations: {
        update(state, str) {
            state.navList = str
        },
        userInfo(state, str) {
            state.userInfo = str
        },
    },
    getters: {
        navList(state) {
            return state.navList
        },
        getUser(state) {
            return state.userInfo
        }
    }
})