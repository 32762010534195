// share.js
 
// url - 需要分享的页面地址（当前页面地址）
// title - 分享的标题（文章标题）
 
function toQQzone (title) {
    let url = encodeURIComponent(window.location.href)
    title = encodeURIComponent(title)
    window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&title=${title}&desc=${title}&summary=${title}&site=${url}`)
}

function toQQ (title) {
    let url = encodeURIComponent(document.location)
    title = encodeURIComponent(title)
    window.open(`https://connect.qq.com/widget/shareqq/index.html?url=${url}&title=${title}&source=${url}&desc=${title}&pics=`)
}

function toWeibo (title) {
    let url = encodeURIComponent(window.location.href)
    title = encodeURIComponent(title)
    window.open(`https://service.weibo.com/share/share.php?url=${url}&title=${title}&pic=&appkey=&sudaref=`)
}

function toWechat (title) {
    let url = encodeURIComponent(window.location.href)
    title = encodeURIComponent(title)
    // console.log(21, process.env.NUXT_ENV_APISERVER)
    window.open(`${process.env.NUXT_ENV_APISERVER}/sharetoWechat?url=${url}&title=${title}`)
}

export default {
    toQQ,
    toWeibo,
    toWechat
}