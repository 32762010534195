const url = {
  bannerIndex: 'banner/index',//首页banner
  bannerOneban: 'banner/oneban',//banner

  login: 'auth/login',//手机密码登录
  loginCode: 'auth/codelogin',//手机验证码登录
  loginRegister: 'auth/register',//注册
  loginRetrieve: 'auth/retrieve',//忘记密码
  loginSms: 'auth/sms',//发送短信
  authWechatopenredirect: 'auth/wechatopenredirect',//微信授权登录
  authWxbind: 'auth/wxbind',//微信绑定

  // 用户
  memberInfo: 'member/info',//用户信息
  memberEditbasic: 'member/editbasic',//修改基本信息
  memberEditpersonal: 'member/editpersonal',//修改个人信息

  // 获取shi
  font_regionCity: 'font_region/city',//市

  // 基础接口
  default: 'default/index',
  default2: 'default/index2',
  // 基础接口-获取不同主题
  themeList: 'default/theme_list',
  singleInfo: 'single/info',// 用户协议
  // 图片上传
  defaultUpload: 'default/upload',
  // 首页
  index: 'index/index',
  //获取QQ
  getqq: 'index/qq',
  // 搜索
  search: 'default/search',
  // 视频分类切换
  videos: 'index/videos',

  problemIndex: 'problem/index',//常见问题

  // 收藏
  favoritesIndex: 'favorites/index',//获取收藏夹列表
  favoritesInfo: 'favorites/info',//获取收藏夹详情
  collectionColl: 'collection/coll',//收藏
  collectionCancelcoll: 'collection/cancelcoll',//取消收藏
  favoritesAdd: 'favorites/add',//创建收藏夹
  favoritesEdit: 'favorites/edit',//编辑收藏夹
  favoritesDel: 'favorites/del',//删除收藏夹

  download_cartJoin: 'download_cart/join',//加入清单
  download_cartDel: 'download_cart/del',//一处清单
  download_cartIndex: 'download_cart/index',//清单列表
  downloadZipdown: 'download/zipdown',//清单列表


  font_followFollow: 'font_follow/follow',//关注

  materialList: 'material/index',// 字体素材列表
  materialInfo: 'material/info',//素材详情
  downloadDown: 'download/down',//素材/变形宝典下载
  downloadDownTest: 'download/downtest',//素材/变形宝典下载--测试
  downloadZipdowncount: 'download/zipdowncount', // 字体素材下载次数

  fontSpecial: 'font_special/index',//专题列表
  fontSpecialInfo: 'font_special/info',//专题详情

  onlistIndex: 'onlist/index',//每周上榜列表
  onlistInfo: 'onlist/info',//每周上榜详情

  fontTaskIndex: 'font_task/index',//每周一字列表
  fontTaskInfo: 'font_task/info',//每周一字详情
  fontTaskUploads: 'font_task/uploads',//每周一字上传

  onlistSearchs: 'onlist/searchs',//字体搜索
  onlistSearchsInfo: 'onlist/searchsinfo',//字体搜索详情

  materialBaodian: 'material/baodian',//变形宝典搜索
  materialBaodianpp: 'material/baodianpp',//变形宝典偏旁
  materialCases: 'material/cases',//变形宝典案例

  videoIndex: 'video/index',//视频列表
  videoInfo: 'video/info',//视频详情
  videoPlay: 'video/play',//视频播放
  videoPlaypause: 'video/playpause',//视频播放时长记录
  videoPlayrecord: 'video/playrecord',//视频续播时长
  videoDown: 'video/down',//源文件下载
  videoTask: 'video/task',//上传作业
  videoTaskedit: 'video/taskedit',//修改作业
  videoTaskdel: 'video/taskdel',//删除作业
  videoPlayok: 'video/playok',//播放视频完成
  good: 'fabulous/good',//点赞
  comment:'video/goodsinfo',//查询某个评论

  member_perksIndex: 'member_perks/index',//会员介绍
  member_siteIndex: 'member_site/index',//会员购买信息
  member_orderQrcode: 'member_order/qrcode',//支付二维码
  member_orderPaytype: 'member_order/orderpaytype',//查询扫码订单支付状态
  member_orderSearch: 'member_order/searchorder',//查询微信订单状态
  member_orderSubexpress: 'member_order/subexpress',//用户支付成功提交收货信息

  memberMyworks: 'member/myworks',//我的-每周一字
  memberListworks: 'member/listworks',//我的-每周上榜
  MyworksOpen: 'font_task/open',
  MyworksHide: 'font_task/hide',
  MyworksDelete: 'font_task/del',
  download_recordIndex: 'download_record/index',//我的-下载记录
  video_viewing_recordIndex: 'video_viewing_record/index',//我的-学习记录
  video_viewing_recordDel: 'video_viewing_record/del',//我的-移除学习记录
  memberFans: 'member/fans',//我的-粉丝
  memberFollow: 'member/follow',//我的-粉丝
  mobileBind: 'member/mobilebind',//我的-换绑手机号
  mobileBindin: 'member/mobilebindin',//我的-绑定手机号
  freeMobileBindin: 'member/wxhbind',
  videTask: 'video_task/index',//作业记录
  good_rewardRecord: 'good_reward_record/index',//获取奖品列表
  good_rewardEdit: 'good_reward_record/edit',//奖品列表-填写收货信息

  noticeIndex: 'notice/index',//消息中心
  noticeNews: 'notice/news',//最新消息
  noticeRead: 'notice/read',//全部已读
  noticeReadone: 'notice/readone',//单个已读
  noticeCateNumber: 'notice/unread',//单个已读

  // 订单、发票相关接口
  userOrder: 'member_order/index',//订单列表
  userInvoiced: 'member_order/invoiced',//待开票列表
  memberInvoiceRecord: 'member_invoice_record/invoicing',// 开发票
  userInvoicedHistory: 'member_invoice_record/index',//开票历史

  messageAdd: 'message/add',// 建议反馈

  myCoupon: 'member/mycoupon',// 我的优惠券
  couponRecord: 'coupon_record/available',// 获取可用优惠券数量及列表
  redeemRecord: 'redeem_record/index',//兑换记录
  redeem: 'member_order/exchange',//兑换

  bindbaodian: 'member/bindbaodian', //绑定宝典订单号

  baodianinfo:'member/baodianinfo', //变形宝典未绑定信息
  book_index:'book/index', //变形宝典购书方式

  match_info:'events/info', //大赛详情

  events_works_add:'events_works/add', //提交作品

  events_list:'events/index', //大赛列表
  events_works_edit:'events_works/edit', //修改作品
  events_works_info:'events_works/info', //作品详情
  events_myworks:'events_works/myworks', //我的作品
  events_delworks:'events_works/del', //删除作品
  events_ranking:'events_ranking/index',//获奖公示
  uploadfile:'default/uploadfile',//上传接口

  video_activity_list:'video_activity/index',//周末卷卷堂列表
  video_activity_info:'video_activity/info',//周末卷卷堂详情
  video_activity_good:'video_activity/goods_task',//优秀作业列表


  integral_goods:'integral_goods/index',//积分列表
  integral_order_create:'integral_exchange/add',//兑换
  integral_subexpress:'integral_exchange/subexpress',//填写收货信息
  integral_record:'integral_record/index',//流水记录
  integral_task:'integral_record/task',//完成情况


  video_random:'video/random',//获取随机视频


  logout: 'member/cancel',
  
  auditionIndex: 'video/gratis',//试听专区列表
  
  bannerecord: 'banner_link_record/add',//banner点击统计次数接口对接
  
  // 邀请和提现
  invite_record_index:'invite_record/index',//邀请记录
  cash_record_index:'cash_record/index',//提现记录
  cash_record_apply_cashalipay:'cash_record/apply_cashalipay',//申请提现-支付宝
  cash_record_apply_cash:'cash_record/apply_cash',//申请提现-微信
  invite_record_invite:'invite_record/invite',//推荐给好友
  member_addcash:'member/addcash',//设置提现密码
  member_editcash:'member/editcash',//修改提现密码

  invite_record_reduction:'invite_record/reduction',//获取邀请优惠价格

}

export default url
